import React from "react";

import { Link } from "gatsby"
import {siteMetadata} from "../../gatsby-config.js"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Warning from "components/Typography/Warning.js";

import LinearProgress from "@material-ui/core/LinearProgress"

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import useSiteMetadata from "../hooks/use-site-metadata"

import { gql, useQuery } from "@apollo/client"

const useStyles = makeStyles(styles);

const DynamicCards = () => {
  const classes = useStyles()

  const { baseImageURL } = useSiteMetadata()



  var PROJECTS_QUERY = gql`
  {
      projects: projectsCollection (filter: { published: true, featured: true}, limit:12) {
        image {
          path
        }
        title
        title_slug
      }
    }
  `

  const { loading, error, data } = useQuery(PROJECTS_QUERY)

  if (loading) return <LinearProgress />
  // if (error) return `Error! ${error.message}`


  const items = data.projects.map(project => {
    return {
      title: project.title,
      // img: cmsURL+"storage/uploads"+project.image.path,
      img: baseImageURL+project.image.path,
      url: "project/"+project.title_slug
    }
  })

  // const items = [
  //   {
  //     title: "Project1",
  //     img: "https://images.unsplash.com/photo-1517089596392-fb9a9033e05b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
  //   },
  //   {
  //     title: "Project2",
  //     img: "https://images.unsplash.com/photo-1563257764-ec4bd2983cbe?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
  //   },
  //   {
  //     title: "Project3",
  //     img: "https://images.unsplash.com/photo-1563166423-a78566af3dbe?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
  //   },
  // ]

  
  // console.log(items)


  const cards = items.map(item => (
    <GridItem xs={12} sm={4} md={4}>
        <Card blog>
          <CardHeader image style={{height:"20vh"}}>
            <Link to={item.url}  style={{height:"20vh"}}>
              <img src={item.img} alt="..."  style={{height:"100%", display:"inline-block", margin: "0 auto", verticalAlign: "middle", margin: "auto", position: "absolute"}} />
            </Link>
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: `url(${item.img})`,
                opacity: "1",
              }}
            />
          </CardHeader>
          <CardBody>
            {/* <Warning> */}
              <h6 className={classes.cardCategory}><Link to={item.url}>{item.title}</Link></h6>
            {/* </Warning> */}
            <h4 className={classes.cardTitle}>
              {/* <a href="#pablo" onClick={e => e.preventDefault()}>
                {item.title}
              </a> */}
            </h4>
          </CardBody>
        </Card>
      </GridItem>
  ))

  return (
    <GridContainer>      
        {cards}
    </GridContainer>
  )
}

export default DynamicCards
