module.exports = {
  pathPrefix: `/mini-gatsbyv2-material-kit-react`,
  siteMetadata: {
    author: 'https://embridea.gr',
    title: 'Complete Construction',
    siteUrl: 'https://completeconstruction.gr',
    cmsURL: 'https://cloud.completeconstruction.gr/',
    logo: 'https://cloud.completeconstruction.gr/storage/uploads/2020/10/01/5f76149c6a26elogo.png',
    logo_black: 'https://cloud.completeconstruction.gr/storage/uploads/2021/03/12/604ba3360c7d7logo_complete_black.png',
    // baseImageURL: 'http://cloud.completeconstruction.gr/api/cockpit/image?token=account-44cfd049e43762568a8d18270f0c6a&o=1&',
    baseImageURL: 'https://cloud.completeconstruction.gr/api/imagestyles/style/large?token=account-f78d7e702ad39285beb47738e16c2d&output=1&src=/storage/uploads',
    mediaURL: 'https://cloud.completeconstruction.gr/api/imagestyles/style/large?token=account-f78d7e702ad39285beb47738e16c2d&output=1&src=',
    token: 'token=f89b8c812bb8ff8583eab0e17306ef',
    keywords: ['κατασκευαστική εταιρεία', 'Πήλιο', 'τεχνικό γραφείο', 'Πηλιορίτικη', 'Βόλος', 'αρχιτεκτονική', 'κατασκευές', 'αντιπαροχή', 'έκδοση οικοδομικών αδειών', 'ανακαίνιση'],
    pages: [
      {
        title: "Προφιλ",
        url: "/about",
        anchors: ["philosophy", "office", "team"]
      },
      {
        title: "Υπηρεσιες",
        url: "/services",
      },
      {
        title: "Εργα",
        url: "/projects",
      },
      {
        title: "Πωλησεις",
        url: "/listings",
      },
      {
        title: "Επικοινωνια",
        url: "/contact",
      },
    ]
  },
  plugins: [
    'gatsby-plugin-resolve-src',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-offline',
    'gatsby-plugin-sass',
    'gatsby-plugin-material-ui',
    {
      resolve: "gatsby-plugin-netlify",
      options: {
      headers: {
          "/*": [
            "X-XSS-Protection: 1; mode=block",
            "X-Content-Type-Options: nosniff",
            "Referrer-Policy: same-origin",
            `Content-Security-Policy: frame-ancestors 'self' https://cloud.completeconstruction.gr`,
          ],
        },
      },
    },
    // {
    //   resolve: `gatsby-plugin-material-ui`,
    //   options: {
    //     pathToTheme: 'src/theme.js',
    //   },
    // },
    // 'gatsby-theme-material-ui',
    // {
    //   resolve: `gatsby-theme-material-ui`,
    //   options: {
    //     webFontsConfig: {
    //       fonts: {
    //         google: [
    //           {
    //             family: `Montserrat`,
    //             variants: [`300`, `400`, `500`],
    //           },
    //         ],
    //       },
    //     },
    //   },
    // },
    {
      resolve: `gatsby-plugin-create-client-paths`,
      options: { prefixes: [`/project/*`, `/listing/*`] },
    },
    {
      resolve: 'gatsby-plugin-apollo',
      options: {
        uri: 'https://cloud.completeconstruction.gr/api/graphql/query?token=f89b8c812bb8ff8583eab0e17306ef'
      }
    },
    {
      resolve: `gatsby-source-graphql`,
      options: {
        typeName: `Cockpit`,
        fieldName: `cmsAPI`,
        url: `https://cloud.completeconstruction.gr/api/graphql/query?token=f89b8c812bb8ff8583eab0e17306ef`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#663399',
        theme_color: '#663399',
        display: 'minimal-ui',
        icon: 'src/assets/img/favicon.png', // This path is relative to the root of the site.
      },
    },{
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-DCX6C8NSEW", // Google Analytics / GA
        ],
      },
    }
    
    // ,{
    //   resolve: "gatsby-plugin-seo",
    //   options: {
    //     siteName: "Complete Construction",
    //     defaultSiteImage: "https://cloud.completeconstruction.gr/storage/uploads/2020/10/01/5f76149c6a26elogo.png",
    //     siteUrl: "https://dev.completeconstruction.gr",
    //     globalSchema: `{
    //         "@type": "WebSite",
    //         "@id": "https://dev.completeconstruction.gr/#website",
    //         "url": "https://dev.completeconstruction.gr",
    //         "name": "Complete Construction",
    //         "publisher": {
    //           "@id": "https://dev.completeconstruction.gr/about/"
    //         },
    //         "image": {
    //           "@type": "ImageObject",
    //           "@id": "https://cloud.completeconstruction.gr/storage/uploads/2020/10/01/5f76149c6a26elogo.png/#logo",
    //           "url": "https://cloud.completeconstruction.gr/storage/uploads/2020/10/01/5f76149c6a26elogo.png",
    //           "caption": "Complete Construction Logo"
    //         }
    //       }`
    //   }
    // }
  ],
}